import { useEffect } from "react";
import useAuth from "../hooks/useAuth";
function useUser() {
  const { setAuth } = useAuth();
  const user = JSON.parse(localStorage.getItem("user"));
  useEffect(() => {
    if (user)
      setAuth({ user, roles: user.role, token: localStorage.getItem("token") });
  }, []);
  if (user) {
    return user;
  }
  return null;
}

export default useUser;
