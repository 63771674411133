import React, { lazy, useEffect } from "react";
import "./App.css";
// import "antd/dist/antd.css";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate
} from "react-router-dom";
import { themeChange } from "theme-change";
import checkAuth from "./app/auth";
import initializeApp from "./app/init";
import useUser from "./hooks/useUser";
import { ROLES } from "./constants";
import useAuth from "./hooks/useAuth";
import RequireAuth from "./components/RequireAuth";

// Importing pages
const Layout = lazy(() => import("./containers/Layout"));
const Login = lazy(() => import("./pages/Login"));
const LoginExposant = lazy(() => import("./pages/LoginExposant"));
const ForgotPassword = lazy(() => import("./pages/ForgotPassword"));
const Register = lazy(() => import("./pages/Register"));
const Documentation = lazy(() => import("./pages/Documentation"));
const ExposantRegisterPage = lazy(() => import("./pages/ExposantPageRegister"));
const WebForm = lazy(() => import("./pages/WebForm"));
// const Exposant = lazy(() => import("./pages/protected/Exposant"));

// const Datadisplay = lazy(() => import("./pages/protected/Datadisplay"));

// Initializing different libraries
initializeApp();

function App() {
  // const user = useUser();
  // // const isLoggedIn = window.localStorage.getItem("loggedIn");

  const user = useUser();

  useEffect(() => {
    // 👆 daisy UI themes initialization
    themeChange(false);
  }, []);

  return (
    <>
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/login-exposant" element={<LoginExposant />} />
          <Route path="/exposantRegister" element={<ExposantRegisterPage />} />
          <Route path="/web-form" element={<WebForm />} />

          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/register" element={<Register />} />
          <Route path="/documentation" element={<Documentation />} />

          {/* temporary while developing , remove on prod  */}
          {/* <Route path="/app/datadisplay" element={<Datadisplay />} /> */}

          {/* Place new routes over this */}
          <Route
            element={
              <RequireAuth
                allowedRoles={[
                  ROLES.SuperAdmin,
                  ROLES.Admin,
                  ROLES.Exposant,
                  ROLES.Animateur,
                  ROLES.Checkpoint,
                  ROLES.Moderateur
                ]}
              />
            }
          >
            <Route
              path="/app/*"
              element={<Layout allowedRoles={user?.role} />}
            />
          </Route>

          <Route
            path="*"
            element={
              <Navigate
                to={
                  user ? determineRedirectPathBasedOnRole(user.role) : "/login"
                }
                replace
              />
            }
          />
        </Routes>
      </Router>
    </>
  );
}

export default App;

function determineRedirectPathBasedOnRole(userRole) {
  switch (userRole) {
    case ROLES.SuperAdmin:
      return "/app/superadmin";
    case ROLES.Admin:
      return "/app/dashboard";
    case ROLES.Exposant:
      return "/app/exposant";
    case ROLES.Animateur:
      return "/app/datadisplay";
    case ROLES.Checkpoint:
      return "/app/checkpoint";
    case ROLES.Moderateur:
      return "/app/moderateur";
    default:
      return "/app/welcome";
  }
}
