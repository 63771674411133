//export const API_URL = "http://localhost:5000/";
//export const API_URL = "https://pitchcheckinsas-production.up.railway.app/";
export const API_URL = "https://easy-chek-api-449befc9243f.herokuapp.com/";

export const ROLES = {
  SuperAdmin: "superadmin",
  Admin: "admin",
  Animateur: "animateur",
  Exposant: "exposant",
  Moderateur: "moderateur",
  Checkpoint: "checkpoint"
};
